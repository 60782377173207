function initParallax() {

	let scrollY = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
	let parallaxElements = document.querySelectorAll('.js-parallax');

	if (parallaxElements) {
		[...parallaxElements].forEach((parallaxElement) => {

				parallaxElement.style.transform = "translateY(" + (scrollY / 20 ) + "%)";

		})
	}

}

window.addEventListener("scroll", initParallax);

export default initParallax;
