
  $( document ).ready(function() {
    $(".nav__list").find('a').click(function(event){
        event.preventDefault();
        $('html,body').animate({scrollTop:$(this.hash).offset().top});
    });


    $(".nav__mobile__list").find('a').click(function(event){
        event.preventDefault();
        if ($(this).attr('href') === undefined) {
          $('.reservation__button').addClass('is-active');
          $('#resengo-booknow').addClass('is-active');
          $('html, body').scrollTop(0);
          $('html, body').css("overflow-y", "hidden")
        } else {
          $('html,body').animate({scrollTop:$(this.hash).offset().top});
        }
    });

    $(".rode-balk").find('a').click(function(event){
        event.preventDefault();
        $('html,body').animate({scrollTop:$(this.hash).offset().top});
    });

    $('.nav__button').click(function(){
      if($(this).hasClass('active')){
        $(this).removeClass('active');
        $('html').removeClass('mobile-active');
        $('.nav__mobile').removeClass('active');
      } else {
        $('html').addClass('mobile-active');
        $(this).addClass('active');
        $('.nav__mobile').addClass('active');
      }
    });

    $(".nav__mobile__list").find('a').click(function(){
      $('.nav__button').removeClass('active');
      $('.nav__mobile').removeClass('active');
      $('html').removeClass('mobile-active');
    });

    var documentHeight = $(document).height();

    $('.nav__mobile').css('height', documentHeight + 'px');
  });
