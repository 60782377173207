// import $ from "jquery";

function initMenu() {
  $('.menu__tabs .menu__tabs--links a').on('click', function (e) {
      var tabOpened = $(this).attr('href');
      $(tabOpened).slideDown(200).siblings().slideUp(200);

      $(this).parent('li').addClass('menu__tabs--active').siblings().removeClass('menu__tabs--active');
      e.preventDefault();
  });
}

export default initMenu;
