function initSwiper() {
    var mySwiper = new Swiper ('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,   
        effect: 'fade',
        speed: 1500,
        allowTouchMove: false,

        autoplay: {
            delay: 5000,
          },        
      })
}

export default initSwiper;