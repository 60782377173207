import 'babel-polyfill';

import initMenu from './components/menu/menu';
// import initAnchor from './components/navigation/navigation';
import initParallax from './components/parallax/parallax'
import initSwiper from './components/header/header';
// import initReservation from './components/reservation/reservation.js';

import './components/navigation/navigation';

initMenu();
initSwiper();
initParallax();
// initReservation();
// initAnchor();
